import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { Alert } from '@ctw/shared/components/alert';
import { PatientMedicationsAllProps } from '@ctw/shared/content/medications/patient-medications-all';
import { PatientMedicationsOutsideProps } from '@ctw/shared/content/medications/patient-medications-outside';
import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import { ZapIFrameConfig } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import { CTWProvider } from '@ctw/shared/context/ctw-context';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import { tw } from '@ctw/shared/utils/tailwind';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  sendOnAddToRecord,
  sendOnPatientSave,
  sendOnResourceSave,
} from '../utils/send-zap-message-event';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { isAuthTokenExpired } from '@ctw/shared/utils/auth';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useZuiTheme } from '@ctw/shared/context/zus-ui-provider';

export function ZapV2() {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const telemetry = useTelemetry();
  const { setLocalizations, setTheme } = useZuiTheme();

  useEffect(() => {
    telemetry.logger.info('Mounting zap in child frame');

    debouncedPostRefreshMessage();
    return () => {
      telemetry.logger.info('Unmounting zap from child frame');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telemetry]);

  const zapConfig = useMemo(() => {
    try {
      return JSON.parse(searchParams.get('config') ?? 'null') as ZapIFrameConfig;
    } catch (e) {
      return null;
    }
  }, [searchParams]);

  if (zapConfig?.CTWProviderProps.locals) {
    setLocalizations(zapConfig.CTWProviderProps.locals);
  }
  if (zapConfig?.iframeTheme) {
    setTheme(zapConfig.iframeTheme);
  }

  // zapProps is a memoized version of zapConfig.ZusAggregatedProfileProps. It creates local handlers where callback
  // functions may normally be used. This is so the local running ZAP can transparently run callback in the parent window.
  const zapProps = useMemo(() => {
    const baseProps = zapConfig?.ZusAggregatedProfileProps ?? {};
    const medOutsideProps = baseProps.medicationsOutsideProps;
    const medAllProps = baseProps.medicationsAllProps;

    function propsReplaceOnAddToRecord(
      props: PatientMedicationsOutsideProps | PatientMedicationsAllProps | undefined,
      component: 'medications-outside' | 'medications-all',
    ) {
      if (!props) return undefined;
      return {
        ...props,
        // Replace onAddToRecord with a function that sends a message to the parent window and waits for the callback
        onAddToRecord: async (medication: MedicationStatementModel) => {
          await sendOnAddToRecord(telemetry.logger, medication, component);
        },
      };
    }

    return {
      ...baseProps,
      medicationsOutsideProps: propsReplaceOnAddToRecord(medOutsideProps, 'medications-outside'),
      medicationsAllProps: propsReplaceOnAddToRecord(medAllProps, 'medications-all'),
    };
  }, [telemetry.logger, zapConfig?.ZusAggregatedProfileProps]);

  useEffect(() => {
    const handleTokenReceivedMessage = (event: {
      data: { type: string; payload: { accessToken: string } };
    }) => {
      if (event.data.type === 'ZusToken') {
        setAccessToken(event.data.payload.accessToken);
      }
    };

    window.addEventListener('message', handleTokenReceivedMessage);
    return () => {
      window.removeEventListener('message', handleTokenReceivedMessage);
    };
  }, [setAccessToken]);

  const postRefreshMessage = () => {
    window.parent.postMessage(
      {
        type: 'ZusTokenRequest',
      },
      '*',
    );
  };

  const debouncedPostRefreshMessage = debounce(postRefreshMessage, 10_000, {
    leading: true,
  });

  useEffect(() => {
    if (isAuthTokenExpired(accessToken ?? '')) {
      debouncedPostRefreshMessage();
    }
  }, [accessToken, debouncedPostRefreshMessage]);

  if (!accessToken) {
    return <LoadingSpinner centered message="Loading..." />;
  }

  if (!zapConfig) {
    return (
      <div className={tw`w-full`}>
        <Alert type="error" header="Missing Zus Aggregated Profile Configuration" />
      </div>
    );
  }

  return (
    <CTWProvider
      onAuthTokenExpiration={() => {
        debouncedPostRefreshMessage();
      }}
      {...zapConfig.CTWProviderProps}
      builderId={zapConfig.CTWProviderProps.builderId}
      service="zap"
      serviceVersion={import.meta.env.VITE_GIT_SHA}
      serviceVariant="zap-v2"
      authToken={accessToken}
      onResourceSave={(resource, action, error) =>
        sendOnResourceSave(telemetry.logger, resource, action, error)
      }
      featureFlags={{
        ...zapConfig.CTWProviderProps.featureFlags,
        openCCDAInNewTab: false,
      }}
    >
      <PatientProvider
        {...zapConfig.PatientProviderProps}
        onPatientSave={(data) => sendOnPatientSave(telemetry.logger, data)}
      >
        <ZusAggregatedProfile {...zapProps} />
      </PatientProvider>
    </CTWProvider>
  );
}
