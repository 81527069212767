import { ZusAggregatedProfileProps } from './zus-aggregated-profile';
import { CTWProviderProps } from '@ctw/shared/context/ctw-context';
import { PatientResourceID, ThirdPartyID } from '@ctw/shared/context/patient-provider';
import { Locals } from '@ctw/shared/utils/i18n';
import { ZuiTheme } from '@ctw/config/zui';

export type ZapIFrameConfig = {
  CTWProviderProps: CTWProviderProps & { locals?: Locals };
  PatientProviderProps: ThirdPartyID | PatientResourceID;
  ZusAggregatedProfileProps: ZusAggregatedProfileIFrameProps;
  iframeTheme: ZuiTheme | undefined;
};

export const ZapIFrameConfigMessageType = 'ZusAggregatedProfileIFrameConfig';
export const ZapIFrameReadyMessageType = 'ZusAggregatedProfileIFrameReady';
export const ZapOnResourceSaveMessageType = 'ZusAggregatedProfileOnResourceSave';
export const ZapOnPatientSaveMessageType = 'ZusAggregatedProfileOnPatientSave';
export const ZapOnAddToRecordMessageType = 'ZusAggregatedProfileOnAddToRecord';
export const ZapOnConfigReceivedMessageType = 'ZusAggregatedProfileConfigReceived';

type ZusAggregatedProfileIFrameProps = ZusAggregatedProfileProps & {
  overrideIframeUrl?: string;
  iframeId?: string;
};
