import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { PatientFormData } from '@ctw/shared/content/forms/actions/patients';
import {
  ZapOnAddToRecordMessageType,
  ZapOnPatientSaveMessageType,
  ZapOnResourceSaveMessageType,
} from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import type { Resource } from 'fhir/r4';
import { MessageEventPromise } from './message-event-promise';
import { IScopedDelegatedLogger } from '@ctw/shared/utils/scoped-delegated-logger';

export function sendOnResourceSave(
  logger: IScopedDelegatedLogger,
  resource: Resource,
  action: string,
  error?: Error,
) {
  const data = {
    error: error?.message,
    resource,
    action,
  };
  window.parent.postMessage(
    {
      type: ZapOnResourceSaveMessageType,
      payload: data,
      // todo: remove deprecated "resource", "action", "error"
      resource,
      action,
      error,
    },
    '*',
  );

  logger.debug('sendOnResourceSave: sent message to parent window', {
    data: {
      resource: {
        resourceType: resource.resourceType,
        id: resource.id,
      },
      action,
      error,
    },
  });
}

export function sendOnPatientSave(
  logger: IScopedDelegatedLogger,
  patientFormData: PatientFormData,
) {
  window.parent.postMessage(
    {
      type: ZapOnPatientSaveMessageType,
      payload: patientFormData,
    },
    '*',
  );
  logger.debug('sendOnPatientSave: sent message to parent window');
}

export function sendOnAddToRecord(
  logger: IScopedDelegatedLogger,
  medStatement: MedicationStatementModel,
  component: string,
) {
  const { resource, includedResources } = medStatement;
  // Send an "onResourceSave" message to the parent window and wait for the response.
  const messagePromise = new MessageEventPromise(ZapOnAddToRecordMessageType).sendMessage({
    resource,
    includedResources,
    component,
  });

  logger.debug('sendOnAddToRecord: sent message to parent window', {
    data: {
      resource: {
        resourceType: resource.resourceType,
        id: resource.id,
      },
    },
  });

  return messagePromise;
}
