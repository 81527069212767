import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SalesforceV1 } from './zaps/salesforce-v1';
import { ZapV1 } from './zaps/zap-v1';
import { ZapV2 } from './zaps/zap-v2';
import { PageError } from '@ctw/shared/components/errors/page-error';
import { ZusUiProvider } from '@ctw/shared/context/zus-ui-provider';

const RouteDefinitions = () => {
  const navigate = useNavigate();

  return (
    <ZusUiProvider service="zap" navigate={navigate} pathname={useLocation}>
      <Routes>
        <Route path="/" element={<ZapV1 />} />
        <Route path="/v1" element={<ZapV1 />} />
        <Route path="/v2" element={<ZapV2 />} />
        <Route path="/salesforce/v1" element={<SalesforceV1 />} />
        <Route path="/health" element={<>OK</>} />
      </Routes>
    </ZusUiProvider>
  );
};

interface AppProps {
  isInsideIFrame: boolean;
}

export function App({ isInsideIFrame }: AppProps) {
  if (!isInsideIFrame) {
    return (
      <PageError
        title="Proxy Authentication Required"
        message="This page will only authenticate and load when embedded inside an iframe. For assistance, get in contact with your representative at Zus Health."
        errorCode="HTTP ERROR 407"
        supportEmailSubject="Help Embedding Zus Aggregated Profile"
      />
    );
  }
  return (
    <BrowserRouter>
      <RouteDefinitions />
    </BrowserRouter>
  );
}
