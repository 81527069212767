import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { PatientMedicationsAllProps } from '@ctw/shared/content/medications/patient-medications-all';
import { PatientMedicationsOutsideProps } from '@ctw/shared/content/medications/patient-medications-outside';
import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import {
  ZapIFrameConfig,
  ZapIFrameConfigMessageType,
  ZapIFrameReadyMessageType,
  ZapOnConfigReceivedMessageType,
} from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import { CTWProvider } from '@ctw/shared/context/ctw-context';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import { omit } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import {
  sendOnAddToRecord,
  sendOnPatientSave,
  sendOnResourceSave,
} from '../utils/send-zap-message-event';
import { useZapConfig } from '../zap-config/use-zap-config';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useZuiTheme } from '@ctw/shared/context/zus-ui-provider';

// todo: replace this with the imported ZapMessageEvent from @ctw/shared once v1.63.0 is deployed to all EHRs
type ZapIFrameConfigMessage = {
  type: string;
  config?: ZapIFrameConfig; // deprecated
  payload: ZapIFrameConfig;
};

export function ZapV1() {
  const { zapConfig, setZapConfig } = useZapConfig();
  const telemetry = useTelemetry();
  const { setLocalizations, setTheme } = useZuiTheme();

  useEffect(() => {
    telemetry.logger.info('Mounting zap in child frame');
    return () => {
      telemetry.logger.info('Unmounting zap from child frame');
    };
  }, [telemetry]);

  // zapProps is a memoized version of zapConfig.ZusAggregatedProfileProps. It creates local handlers where callback
  // functions may normally be used. This is so the local running ZAP can transparently run callback in the parent window.
  const zapProps = useMemo(() => {
    const baseProps = zapConfig?.ZusAggregatedProfileProps ?? {};
    const medOutsideProps = baseProps.medicationsOutsideProps;
    const medAllProps = baseProps.medicationsAllProps;

    function propsReplaceOnAddToRecord(
      props: PatientMedicationsOutsideProps | PatientMedicationsAllProps | undefined,
      component: 'medications-outside' | 'medications-all',
    ) {
      if (!props) return undefined;
      return {
        ...props,
        // Replace onAddToRecord with a function that sends a message to the parent window and waits for the callback
        onAddToRecord: async (medication: MedicationStatementModel) => {
          await sendOnAddToRecord(telemetry.logger, medication, component);
        },
      };
    }

    return {
      ...baseProps,
      medicationsOutsideProps: propsReplaceOnAddToRecord(medOutsideProps, 'medications-outside'),
      medicationsAllProps: propsReplaceOnAddToRecord(medAllProps, 'medications-all'),
    };
  }, [telemetry.logger, zapConfig?.ZusAggregatedProfileProps]);

  useEffect(() => {
    const handler = ({ data }: { data: ZapIFrameConfigMessage }) => {
      if (data.type === ZapIFrameConfigMessageType) {
        const payload = data.config ?? data.payload;
        telemetry.logger.debug('received ZapIFrameConfig message in child frame', {
          ...payload,
          CTWProviderProps: omit(payload.CTWProviderProps, ['authToken', 'headers', 'theme']),
        });
        // todo: remove deprecated config
        setZapConfig(data.config ?? data.payload);

        window.parent.postMessage(
          {
            type: ZapOnConfigReceivedMessageType,
          },
          '*',
        );
        telemetry.logger.debug('sendOnIFrameConfigReceived: sent message to parent window');
      }
    };

    telemetry.logger.debug('adding ZapIFrameConfig event listener in child frame');
    window.addEventListener('message', handler);

    window.parent.postMessage(
      {
        type: ZapIFrameReadyMessageType,
      },
      '*',
    );

    telemetry.logger.debug('sendOnIFrameReady: sent message to parent window');

    // clean up
    return () => {
      telemetry.logger.debug('cleaning up ZapIFrameConfig event listener in child frame');
      window.removeEventListener('message', handler);
    };
    // eslint-disable-next-line
  }, [telemetry, setZapConfig]);

  if (zapConfig?.CTWProviderProps.locals) {
    setLocalizations(zapConfig.CTWProviderProps.locals);
  }

  if (zapConfig?.iframeTheme) {
    setTheme(zapConfig.iframeTheme);
  }

  if (!zapConfig) {
    return <LoadingSpinner centered message="Loading..." />;
  }

  return (
    <CTWProvider
      {...zapConfig.CTWProviderProps}
      builderId={zapConfig.CTWProviderProps.builderId}
      ehr={zapConfig.CTWProviderProps.ehr}
      service="zap"
      serviceVersion={import.meta.env.VITE_GIT_SHA}
      serviceVariant="zap-v1"
      onResourceSave={(resource, action, error) =>
        sendOnResourceSave(telemetry.logger, resource, action, error)
      }
      featureFlags={{
        ...zapConfig.CTWProviderProps.featureFlags,
        openCCDAInNewTab: false,
      }}
    >
      <PatientProvider
        {...zapConfig.PatientProviderProps}
        onPatientSave={(data) => sendOnPatientSave(telemetry.logger, data)}
      >
        <ZusAggregatedProfile {...zapProps} />
      </PatientProvider>
    </CTWProvider>
  );
}
