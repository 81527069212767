import { tw } from '@ctw/shared/utils/tailwind';
import { faFileCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { omitNil } from '@ctw/shared/utils/omit-nil';

export interface PageErrorProps {
  title: string;
  message?: string;
  errorCode?: string;
  supportEmailSubject?: string;
  supportEmailBody?: string;
}

export const PageError = ({
  title,
  message,
  errorCode,
  supportEmailSubject,
  supportEmailBody,
}: PageErrorProps) => {
  // Note: this component somewhat duplicates link rendering because we can't rely on being in a
  // a ZUI or telemetry context
  const mailToParams = new URLSearchParams(
    omitNil({
      subject: supportEmailSubject,
      body: supportEmailBody,
    }),
  );

  return (
    <div className={tw`ml-10 mt-20 flex flex-col flex-wrap space-y-12 sm:ml-20 lg:ml-80`}>
      <FontAwesomeIcon icon={faFileCircleXmark} className={tw`h-20 w-20 text-content-subtle`} />
      <div className={tw`space-y-6`}>
        <div className={tw`text-2xl font-medium`}>{title}</div>
        {message && <div className={tw`text-base font-medium`}>{message}</div>}
        {errorCode && (
          <div className={tw`text-xs font-medium text-content-subtle`}>{errorCode}</div>
        )}
        <div>
          <a
            href={`mailto:support@zushealth.com?${mailToParams.toString().replaceAll('+', '%20')}`}
            className={tw`cursor-pointer rounded-md fill-primary-text font-sans font-medium text-primary-text no-underline hover:underline focus:no-underline focus-visible:outline-4 focus-visible:outline-offset-[3px] focus-visible:outline-primary-main active:scale-[.98]`}
          >
            Contact support@zushealth.com
          </a>
        </div>
      </div>
    </div>
  );
};
