import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useSalesforceUser } from './salesforce-user-context';
import { useSalesforceEnvironment } from './salesforce-environment-context';
import { getPageEnv } from '@ctw/shared/utils/get-page-env';
import { tw } from '@ctw/shared/utils/tailwind';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { getEHRIntegrationServiceBaseUrl } from '@ctw/shared/api/urls';

interface ZusTokenContextProps {
  zusAccessToken?: string;
}

const defaultContextValue: ZusTokenContextProps = {};

export const ZusTokenContext = createContext<ZusTokenContextProps>(defaultContextValue);

export const ZusTokenProvider = ({ children }: PropsWithChildren<object>) => {
  const [zusAccessToken, setZusAccessToken] = useState<string>();
  const salesforceUser = useSalesforceUser();
  const salesforceEnvironment = useSalesforceEnvironment();
  const zusEnvironment = getPageEnv();

  const ehrHooksUrl = getEHRIntegrationServiceBaseUrl(zusEnvironment);

  useEffect(() => {
    const fetchZusToken = async () => {
      try {
        const tokenResponse = await fetch(`${ehrHooksUrl}/token/salesforce`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              type: 'token-request',
              attributes: {
                accessToken: salesforceUser.user?.access_token,
                organizationId: salesforceEnvironment.environment?.organizationId,
              },
            },
          }),
        });

        if (tokenResponse.status !== 200) {
          throw new Error('Could not get Zus token');
        }

        setZusAccessToken((await tokenResponse.json()).access_token);
      } catch (e) {
        throw new Error('An error occurred while fetching the Zus token.');
      }
    };

    void fetchZusToken();
  }, [salesforceUser, salesforceEnvironment, ehrHooksUrl]);

  const contextValue = useMemo(() => ({ zusAccessToken }), [zusAccessToken]);

  if (!zusAccessToken) {
    return (
      <LoadingSpinner
        className={tw`fixed inset-0 flex h-screen items-center justify-center`}
        message="Getting Zus Access token"
      />
    );
  }

  return <ZusTokenContext.Provider value={contextValue}>{children}</ZusTokenContext.Provider>;
};

export const useZusToken = () => useContext(ZusTokenContext);
