import '@ctw/shared/styles/preflight';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { ZapConfigProvider } from './zap-config/provider';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

const isInsideIFrame = window.location !== window.parent.location;
const container = document.getElementById('root') as HTMLDivElement;

const Root = withErrorBoundary({
  boundaryName: 'ZAP Root',
  includeTelemetryBoundary: true,
  Component: () => (
    <React.StrictMode>
      <ZapConfigProvider>
        <App isInsideIFrame={isInsideIFrame} />
      </ZapConfigProvider>
    </React.StrictMode>
  ),
});

createRoot(container).render(<Root />);
